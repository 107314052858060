import { FC, useEffect, useMemo, useRef, useState } from 'react'
import { FlagsmithProvider } from 'flagsmith/react'
import flagsmith from 'flagsmith/isomorphic'
import { Project } from 'common/project'
import { useUser } from 'common/services/useUser'
import Loader from './base/Loader'
import { useDevSettings } from 'common/services/useDevSettings'
import { useHydrated } from 'common/services/useHydrated'

type FeatureFlagProviderType = {}

const FeatureFlagProvider: FC<FeatureFlagProviderType> = ({ children }) => {
  const [_, setReady] = useState(Date.now())

  const { data } = useUser({})
  const hydrated = useHydrated()
  const { devSettings } = useDevSettings()
  const whitelabel = devSettings.whitelabel || undefined
  const flagsmithWhitelabel = flagsmith.getTrait('whitelabel') || undefined
  const username = data?.username
  const loadingState = flagsmith.loadingState
  const ready = useMemo(() => loadingState?.source !== 'NONE', [loadingState])

  useEffect(() => {
    if (!ready) return
    if (flagsmith.identity !== username || flagsmithWhitelabel !== whitelabel) {
      if (username) {
        flagsmith.identify(username, {
          email: username,
          whitelabel: whitelabel || null,
        })
      } else {
        flagsmith.logout()
      }
    }
  }, [username, flagsmithWhitelabel, whitelabel, ready])

  const flagsmithOptions = useRef({
    cacheFlags: true,
    environmentID: Project.flagsmith,
    identity: username,
    onChange: () => {
      if (flagsmith.loadingState?.source !== 'NONE') {
        // Re-trigger rendering with new ready state
        setReady(Date.now())
      }
    },
    traits: data ? { email: data.username } : undefined,
  })

  return (
    <FlagsmithProvider options={flagsmithOptions.current} flagsmith={flagsmith}>
      {!ready ? (
        <div className='text-center'>
          <Loader />
        </div>
      ) : (
        <>{children}</>
      )}
    </FlagsmithProvider>
  )
}

export default FeatureFlagProvider
