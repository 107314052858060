import { Res } from 'common/types/responses'
import { Req } from 'common/types/requests'
import { service } from 'common/service'
import { responses } from 'common/types/mock-data'

export const mailCategoryService = service
  .enhanceEndpoints({ addTagTypes: ['MailCategory'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getMailCategories: builder.query<
        Res['mailCategories'],
        Req['getMailCategories']
      >({
        providesTags: [{ id: 'LIST', type: 'MailCategory' }],
        query: (q) => ({
          url: `mailbox/mails/filters?subscription_id=${q.subscription}`,
        }),
        // queryFn: () => ({ data: responses.mailCategories }),
      }),
      // END OF ENDPOINTS
    }),
  })

export async function getMailCategories(
  store: any,
  data: Req['getMailCategories'],
  options?: Parameters<
    typeof mailCategoryService.endpoints.getMailCategories.initiate
  >[1],
) {
  return store.dispatch(
    mailCategoryService.endpoints.getMailCategories.initiate(data, options),
  )
}
// END OF FUNCTION_EXPORTS

export const {
  useGetMailCategoriesQuery,
  // END OF EXPORTS
} = mailCategoryService

/* Usage examples:
const { data, isLoading } = useGetMailCategoriesQuery({ id: 2 }, {}) //get hook
const [createMailCategories, { isLoading, data, isSuccess }] = useCreateMailCategoriesMutation() //create hook
mailCategoryService.endpoints.getMailCategories.select({id: 2})(store.getState()) //access data from any function
*/
