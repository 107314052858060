import { FC } from 'react'
import classNames from 'classnames'

type DropdownIconType = {
  className?: string
  fill?: string
}
const SelectIcon: FC<DropdownIconType> = ({ className, ...rest }) => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.7562 9.9367L12.4077 15.3295C12.3546 15.3835 12.2912 15.4264 12.2212 15.4557C12.1513 15.4849 12.0762 15.5 12.0003 15.5C11.9244 15.5 11.8493 15.4849 11.7794 15.4557C11.7094 15.4264 11.646 15.3835 11.5929 15.3295L6.24438 9.9367C6.08776 9.77787 6 9.5641 6 9.34141C6 9.11873 6.08776 8.90496 6.24438 8.74613C6.32145 8.66818 6.4133 8.60627 6.5146 8.56401C6.6159 8.52176 6.72462 8.5 6.83443 8.5C6.94424 8.5 7.05296 8.52176 7.15426 8.56401C7.25556 8.60627 7.34741 8.66818 7.42448 8.74613L12.0003 13.3597L16.5749 8.74613C16.6521 8.66823 16.744 8.60637 16.8454 8.56416C16.9467 8.52194 17.0554 8.5002 17.1653 8.5002C17.2751 8.5002 17.3839 8.52194 17.4852 8.56416C17.5865 8.60637 17.6785 8.66823 17.7556 8.74613C17.9122 8.90496 18 9.11873 18 9.34141C18 9.5641 17.9128 9.77787 17.7562 9.9367Z'
        fill={rest.fill || '#6E6E73'}
      />
    </svg>
  )
}

export default SelectIcon
