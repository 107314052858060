import { BusinessType } from './types/business-type' // we need this to make JSX compile

export const businessTypeDescriptions: Record<BusinessType, string> = {
  ASSOCIATION: 'Association, club or society',
  CHARITY: 'Charity / non-profit',
  LIMITED_COMPANY: 'Limited company (LTD, LP, LLP, LLC, Corp)',
  SOLE_TRADER: 'Individual / sole trader',
  TRUST: 'Trust, foundation or fund',
  UNINCORPORATED: 'Unincorporated / not yet registered',
}

export const BusinessTypeData = [
  {
    label: businessTypeDescriptions.LIMITED_COMPANY,
    value: BusinessType.LIMITED_COMPANY as string,
  },
  {
    label: businessTypeDescriptions.SOLE_TRADER,
    value: BusinessType.SOLE_TRADER as string,
  },
  {
    label: businessTypeDescriptions.CHARITY,
    value: BusinessType.NON_PROFIT as string,
  },
  {
    label: businessTypeDescriptions.TRUST,
    value: BusinessType.TRUST as string,
  },
  {
    label: businessTypeDescriptions.ASSOCIATION,
    value: BusinessType.ASSOCIATION as string,
  },
  {
    label: businessTypeDescriptions.UNINCORPORATED,
    value: BusinessType.UNINCORPORATED as string,
  },
]
