import { useMemo } from 'react'
import { Mail, MailActionType } from 'common/types/responses'

export default function (
  mail: Mail[] | undefined,
  actionType: MailActionType | undefined,
) {
  return useMemo(() => {
    return getActionTotal(mail, actionType)
  }, [mail, actionType])
}

export const getActionTotal = (
  mail: Mail[] | undefined,
  actionType: MailActionType | undefined,
) => {
  let total = 0
  const items: { mail: Mail; price: number }[] = []
  mail?.map((v) => {
    const action = v.actions?.find((v) => v.action_type === actionType)
    items.push({ mail: v, price: action?.price || 0 })
    if (action?.price) {
      total += action.price
    }
  })
  return { items, total }
}
