import React from 'react'
import { Format } from 'common/utils/format'
import { BillingPeriod } from 'common/types/responses'
import Button from 'components/base/forms/Button'
import Card from 'components/base/Card'
import { usePaymentWarning } from 'common/hooks/usePaymentWarning'
import PaymentWarning from './PaymentWarning'
import { useDefaultSubscription } from 'common/services/useDefaultSubscription'
import { useGetManageSubscriptionQuery } from 'common/services/useManageSubscription'
import dayjs from 'dayjs'
import clearCBCookies from 'project/clearCBCookies'
import CardTitle from './CardTitle'

type SubscriptionInfoCardProps = {
  theme?: 'default' | 'condensed'
  className?: string
}

const SubscriptionInfoCard: React.FC<SubscriptionInfoCardProps> = ({
  className,
  theme = 'default',
}) => {
  const { defaultSubscriptionId, hasPermission, subscriptionDetail } =
    useDefaultSubscription()
  const { data: manageSubscriptionUrl, refetch } =
    useGetManageSubscriptionQuery(
      {
        id: subscriptionDetail?.company_id,
      },
      {
        skip: !subscriptionDetail?.company_id,
      },
    )
  const handleManageSubscription = () => {
    refetch()
    clearCBCookies()
    window.open(manageSubscriptionUrl?.access_url || '', '_blank')
  }
  const { paymentWarning } = usePaymentWarning()
  const isPartnerCustomer =
    subscriptionDetail?.is_partner_customer || !hasPermission('MANAGE_BILLING')
  if (isPartnerCustomer) {
    return null
  }
  return (
    <div className={className}>
      <Card
        className='d-flex flex-column justify-content-between'
        footer={paymentWarning && <PaymentWarning />}
        footerClassName='text-white p-5 bg-dark'
      >
        <div className='flex-fill'>
          <CardTitle title={'PAYMENTS'} subtitle={subscriptionDetail?.name} />
          <h1 className='text-tertiary mb-2 fw-normal no-wrap flex-row align-items-center'>
            <span className='m-0 me-1 text-primary text-nowrap fw-bold'>
              {Format.money((subscriptionDetail?.price || 0) * 100)}
            </span>
            {subscriptionDetail?.billing_period === BillingPeriod.ANNUAL
              ? 'Billed Yearly'
              : 'Billed Monthly'}
          </h1>{' '}
          {subscriptionDetail?.next_payment_date && (
            <div className='mt-2 pb-6 fs-lg'>
              Next payment due:{' '}
              <strong>
                {dayjs(subscriptionDetail.next_payment_date * 1000).format(
                  'Do MMM YYYY',
                )}
              </strong>
            </div>
          )}
        </div>

        {!paymentWarning && (
          <Button onClick={handleManageSubscription}>Manage</Button>
        )}
      </Card>
    </div>
  )
}

export default SubscriptionInfoCard
