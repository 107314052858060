import * as React from 'react'
import { useEffect } from 'react'
const AscenLogo = (props) => {
  return (
    <svg viewBox='0 0 468.26 122' {...props}>
      <defs>
        <linearGradient
          id='a'
          x1='0'
          y1='0'
          x2='1'
          y2='1'
          gradientUnits='objectBoundingBox'
        >
          <stop offset='0' stopColor='#2065be' stopOpacity='1' />
          <stop offset='1' stopColor='#112d9f' stopOpacity='1' />
        </linearGradient>
      </defs>
      <g transform='translate(-361 -84)'>
        <circle
          cx='61'
          cy='61'
          r='61'
          fill='url(#a)'
          transform='translate(361 84)'
        />
        <path
          fill='#fff'
          stroke='rgba(0,0,0,0)'
          d='M449.93 177a3.862 3.862 0 0 1-3.787-2.529l-24.121-56.886-18.006 42.569 13.382-10.725c2.073-1.458 3.772-1.52 4.958-.33a9.363 9.363 0 0 1 1.414 1.714c.8 1.2.711 3.223-1.363 4.894l-24.614 19.733a7.135 7.135 0 0 1-1.943 1.254 4.437 4.437 0 0 1-1.683.306h-1.294c-2.437 0-3.458-1.557-2.538-3.792l26.921-61.681a3.806 3.806 0 0 1 3.79-2.526h1.962a3.809 3.809 0 0 1 3.787 2.526l26.968 61.681c.918 2.236-.053 3.792-2.428 3.792Z'
          data-name='Path 13'
        />
      </g>
      <g
        className='animated'
        style={{ opacity: props.collapsed ? 0 : 100 }}
        transform='translate(-361 -84)'
      >
        <path
          fill='#102b9e'
          d='M558.59 155.338a1.5 1.5 0 0 1 .851-2.506l.435-.142c.9-.331 1.561-.255 2.6.741 2.38 2.749 6.11 4.14 10.192 4.14 5.651 0 9.11-2.177 9.11-5.737 0-8.484-22.24-2.389-22.24-14.69 0-5.542 5.546-8.993 12.681-8.993 4.647 0 9.465 1.923 12.2 4.989a1.427 1.427 0 0 1-.616 2.5l-.409.168c-1.051.4-1.652.05-2.626-.64a12.894 12.894 0 0 0-8.487-3.19c-5.027 0-8.367 1.871-8.367 5 0 8.441 22.334 2.17 22.334 14.76 0 5.855-5.538 9.776-13.572 9.776-5.8 0-11.348-2.414-14.079-6.181'
          data-name='Path 2'
        />
        <path
          fill='#102b9e'
          d='M590.624 144.834c0-9.776 6.57-16.74 15.716-16.74 6.5 0 11.1 2.585 13.735 7.427a1.511 1.511 0 0 1-1.036 2.435l-.42.123a2.085 2.085 0 0 1-2.535-1.114c-2.065-3.242-5.263-4.952-9.592-4.952-6.726 0-11.435 5.249-11.435 12.821 0 7.544 4.709 12.793 11.435 12.793a10.7 10.7 0 0 0 9.53-4.922c.67-.99 1.438-1.5 2.506-1.115l.341.122c1.355.45 1.815 1.12 1.235 2.258-2.582 4.874-7.295 7.6-13.764 7.6-9.055 0-15.716-6.871-15.716-16.739'
          data-name='Path 3'
        />
        <path
          fill='#102b9e'
          d='M641.934 161.577c-9.9 0-16.365-6.651-16.365-16.742 0-9.922 6.549-16.741 16.057-16.741 9.356 0 15.332 6.231 15.517 15.61a1.746 1.746 0 0 1-1.956 1.956H630c.119 7.511 4.69 12.027 11.989 12.027 4.437 0 7.288-1.475 9.551-4.218.8-.784 1.453-1.347 2.536-1.032l.518.17c1.322.4 1.612 1.161.905 2.242-2.868 4.38-7.356 6.724-13.572 6.724Zm-.25-29.744c-6.3 0-10.729 3.732-11.511 10.122h22.667c-.373-6.174-4.549-10.122-11.156-10.122'
          data-name='Path 4'
        />
        <path
          fill='#102b9e'
          d='M679.111 128.175c9.117 0 13.438 5.312 13.438 15.515v15.311a1.769 1.769 0 0 1-1.956 1.956h-.518a1.771 1.771 0 0 1-1.956-1.956V143.96c0-7.959-3.122-11.806-10.066-11.806-7.026 0-9.95 4.68-9.95 12.775v14.072a1.769 1.769 0 0 1-1.955 1.954h-.518a1.768 1.768 0 0 1-1.956-1.954v-28.334a1.769 1.769 0 0 1 1.956-1.956h.518a1.77 1.77 0 0 1 1.955 1.956v4.975c1.431-4.253 5.162-7.468 11.012-7.468Z'
          data-name='Path 5'
        />
        <path
          fill='#102b9e'
          d='M713.521 161.686c-8.005 0-15.242-5.43-15.242-16.854 0-11.339 7.2-16.74 15.159-16.74 5.491 0 10.4 2.688 12.38 8.056v-15.962a1.772 1.772 0 0 1 1.959-1.958h.518a1.771 1.771 0 0 1 1.956 1.958v38.756a1.77 1.77 0 0 1-1.951 1.959h-.518a1.771 1.771 0 0 1-1.959-1.956v-5.284c-1.985 5.308-6.864 8.026-12.3 8.026Zm12.391-16.854c0-7.88-4.633-12.821-11.656-12.821-6.94 0-11.547 4.885-11.547 12.821 0 8.021 4.607 12.906 11.659 12.906 6.966 0 11.544-4.94 11.544-12.906'
          data-name='Path 6'
        />
        <path
          fill='#102b9e'
          d='M752.411 161.686c-8.005 0-15.242-5.43-15.242-16.854 0-11.339 7.2-16.74 15.159-16.74 5.491 0 10.341 2.688 12.383 8.056v-5.479a1.769 1.769 0 0 1 1.956-1.956h.518a1.77 1.77 0 0 1 1.956 1.956v28.273a1.77 1.77 0 0 1-1.956 1.956h-.518a1.769 1.769 0 0 1-1.956-1.956v-5.281c-1.988 5.308-6.867 8.026-12.3 8.026Zm12.389-16.854c0-7.88-4.691-12.821-11.656-12.821-6.94 0-11.547 4.885-11.547 12.821 0 8.021 4.607 12.906 11.659 12.906 6.911 0 11.544-4.94 11.544-12.906'
          data-name='Path 7'
        />
        <path
          fill='#102b9e'
          d='M792.627 128.175c9.117 0 13.438 5.312 13.438 15.515v15.311a1.769 1.769 0 0 1-1.956 1.956h-.518a1.771 1.771 0 0 1-1.956-1.956V143.96c0-7.959-3.122-11.806-10.066-11.806-7.026 0-9.95 4.68-9.95 12.775v14.072a1.769 1.769 0 0 1-1.955 1.954h-.518a1.768 1.768 0 0 1-1.956-1.954v-28.334a1.769 1.769 0 0 1 1.956-1.956h.518a1.77 1.77 0 0 1 1.955 1.956v4.975c1.431-4.253 5.162-7.468 11.012-7.468Z'
          data-name='Path 8'
        />
        <path
          fill='#102b9e'
          d='M825.834 161.38c-7.921 0-11.3-3.619-11.3-13.535v-15.531h-2.88c-1.257 0-1.956-.61-1.956-1.816a1.555 1.555 0 0 1 1.713-1.783h3.123v-4.27a1.917 1.917 0 0 1 1.72-2.1l.58-.117c1.351-.292 2.158.324 2.158 1.7v4.792h8.146c1.29 0 1.956.618 1.956 1.783a1.708 1.708 0 0 1-1.956 1.816h-8.146v15.306c0 9.336 3.64 10.06 8.088 10.016 1.228-.009 2.018.421 2.156 1.522.159 1.26-.348 1.975-1.764 2.135a13.694 13.694 0 0 1-1.638.08Z'
          data-name='Path 9'
        />
        <path
          fill='#102b9e'
          stroke='rgba(0,0,0,0)'
          d='M550.653 161.378a2.317 2.317 0 0 1-2.273-1.518l-14.476-34.138-10.806 25.547 8.03-6.437c1.244-.875 2.265-.912 2.977-.2a5.62 5.62 0 0 1 .848 1.029c.48.718.428 1.935-.817 2.937l-14.772 11.842a4.283 4.283 0 0 1-1.166.752 2.665 2.665 0 0 1-1.009.184h-.777c-1.461 0-2.075-.934-1.522-2.276l16.156-37.016a2.282 2.282 0 0 1 2.273-1.516h1.177a2.286 2.286 0 0 1 2.273 1.516l16.185 37.016c.551 1.342-.033 2.276-1.458 2.276Z'
          data-name='Path 10'
        />
      </g>
    </svg>
  )
}
export default AscenLogo
