import React, { useState } from 'react'
import Card from 'components/base/Card'
import { useDefaultSubscription } from 'common/services/useDefaultSubscription'
import { Format } from 'common/utils/format'
import { Project } from 'common/project'
import { downloadPDF } from '../../../pages/business'
import { kycNeedsVerifying } from 'common/services/useKycStatus'
import CardTitle from './CardTitle'
import { useFlags } from 'flagsmith/react'
import Button from 'components/base/forms/Button'

type AddressCardProps = {
  className?: string
}

const AddressCard: React.FC<AddressCardProps> = ({ className }) => {
  const { subscriptionDetail } = useDefaultSubscription()
  const hasAddress = !!subscriptionDetail?.address?.postal_code
  const { feature_letter_of_certification } = useFlags([
    'feature_letter_of_certification',
  ])
  const [downloadingPDF, setDownloadingPDF] = useState(false)
  const needsVerify = kycNeedsVerifying(subscriptionDetail)

  return (
    <>
      <div className={className}>
        <Card
          footer={
            hasAddress && (
              <>
                <hr />
                <div className='flex-row align-items-center'>
                  <div className='flex-fill text-sm'>
                    <div className='fw-bold ms-2'>Forwarding address</div>
                    <div className=' ms-2'>
                      {Format.address(subscriptionDetail.address)}
                    </div>
                  </div>
                </div>
              </>
            )
          }
          footerClassName='p-5'
        >
          <div className='flex-fill'>
            <CardTitle title={'ADDRESSES'} subtitle={'Your Business Address'} />
            <div className='mt-2 fs-lg'>
              {subscriptionDetail?.company?.legal_name ||
                subscriptionDetail?.company?.trading_name}
              <h3 className='fw-bold'>{Project.address}</h3>
              {feature_letter_of_certification.enabled && !needsVerify && (
                <div className='mt-4'>
                  <Button
                    className='w-100'
                    disabled={downloadingPDF || !subscriptionDetail}
                    onClick={() => {
                      if (!subscriptionDetail) return
                      setDownloadingPDF(true)
                      downloadPDF(`${subscriptionDetail.id}`).finally(() => {
                        setDownloadingPDF(false)
                      })
                    }}
                  >
                    Generate Letter of Certification
                  </Button>
                </div>
              )}
            </div>
          </div>
        </Card>
      </div>
    </>
  )
}

export default AddressCard
