import { FC, HTMLAttributes, ReactNode } from 'react'
import classNames from 'classnames'

type DropdownItemType = HTMLAttributes<HTMLDivElement> & {
  className?: string
  icon?: ReactNode
  selected?: boolean
  danger?: boolean
}
const DropdownItem: FC<DropdownItemType> = ({
  children,
  className,
  danger,
  icon,
  selected,
  ...rest
}) => {
  return (
    <div
      {...rest}
      className={classNames(
        'dropdown-item cursor-pointer user-select-none',
        { 'dropdown-item--danger': danger },
        className,
      )}
    >
      {icon && !selected && (
        <span className='me-2 dropdown-item__icon'>{icon}</span>
      )}
      <div className='flex-fill'>{children}</div>
      {selected && (
        <span className='ms-4 dropdown-item__icon'>
          <span className='fa fa-check' />
        </span>
      )}
    </div>
  )
}

export default DropdownItem
