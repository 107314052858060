import { KeyboardEvent } from 'react'

const KEY_Y = 89
const KEY_Z = 90
export const keys = {
  isBackspace /* istanbul ignore next */(e: KeyboardEvent): boolean {
    // returns bool
    if (!e) return false
    const code = e.keyCode ? e.keyCode : e.which
    return code === 8 && !e.shiftKey && !e.ctrlKey
  },
  isDown /* istanbul ignore next */(e: KeyboardEvent): boolean {
    // returns bool
    if (!e) return false
    const code = e.keyCode ? e.keyCode : e.which
    return code === 40 && !e.shiftKey && !e.ctrlKey
  },
  isEnter /* istanbul ignore next */(e: KeyboardEvent): boolean {
    // returns bool
    if (!e) return false
    const code = e.keyCode ? e.keyCode : e.which
    return code === 13 && !e.shiftKey && !e.ctrlKey
  },
  isEscape /* istanbul ignore next */(e: KeyboardEvent): boolean {
    if (!e) return false
    const code = e.keyCode ? e.keyCode : e.which
    return code === 27 && !e.shiftKey && !e.ctrlKey
  },
  isLeft /* istanbul ignore next */(e: KeyboardEvent): boolean {
    // returns bool
    if (!e) return false
    const code = e.keyCode ? e.keyCode : e.which
    return code === 37 && !e.shiftKey && !e.ctrlKey
  },
  isRedo /* istanbul ignore next */(e: KeyboardEvent): boolean {
    if (!e) return false
    return (
      (e.ctrlKey || e.metaKey) && e.keyCode === (e.shiftKey ? KEY_Z : KEY_Y)
    )
  },
  isRight /* istanbul ignore next */(e: KeyboardEvent): boolean {
    // returns bool
    if (!e) return false
    const code = e.keyCode ? e.keyCode : e.which
    return code === 39 && !e.shiftKey && !e.ctrlKey
  },
  isTab /* istanbul ignore next */(e: KeyboardEvent): boolean {
    // returns bool
    if (!e) return false
    const code = e.keyCode ? e.keyCode : e.which
    return code === 9 && !e.shiftKey && !e.ctrlKey
  },
  isUndo /* istanbul ignore next */(e: KeyboardEvent): boolean {
    if (!e) return false
    return (
      (e.ctrlKey || e.metaKey) && e.keyCode === (e.shiftKey ? KEY_Y : KEY_Z)
    )
  },
  isUp /* istanbul ignore next */(e: KeyboardEvent): boolean {
    // returns bool
    if (!e) return false
    const code = e.keyCode ? e.keyCode : e.which
    return code === 38 && !e.shiftKey && !e.ctrlKey
  },
}
