import { FC, useState } from 'react'
import { useDefaultSubscription } from 'common/services/useDefaultSubscription'
import Head from 'next/head'
import Button from 'components/base/forms/Button'
import Loader from 'components/base/Loader'
import Card from 'components/base/Card'
import TitleValue from 'components/TitleValue'
import { BusinessTypeData } from 'common/BusinessTypeData'
import { countries } from 'common/countries'
import Icon from 'project/Icon'
import dayjs from 'dayjs'
import { kycNeedsVerifying } from 'common/services/useKycStatus'
import useNotLoggedInRedirect from 'common/hooks/useNotLoggedInRedirect'
import Link from 'next/link'
import { Project } from 'common/project'
import { useFlags } from 'flagsmith/react'
import cognito from 'common/cognito' // we need this to make JSX compile

type OwnersPageType = {}
export async function downloadPDF(subscriptionId: string) {
  try {
    const session = await cognito.getSession()
    const jwtToken = await session!.getAccessToken()
    // Fetch the raw PDF content from the server (use the correct endpoint)
    const response = await fetch(
      `${Project.api}customers/subscriptions/${subscriptionId}/pdf-certification`,
      {
        headers: { Authorization: `Bearer ${jwtToken.jwtToken}` },
      },
    )

    // Ensure the response is OK
    if (!response.ok) {
      throw new Error('Failed to fetch the PDF')
    }

    // Get the PDF content as a Blob
    const blob = await response.blob()

    // Create a link element
    const link = document.createElement('a')

    // Create a URL for the Blob
    const url = URL.createObjectURL(blob)

    // Set the link's href to the Blob URL
    link.href = url

    // Set the download attribute with the filename you want
    link.download = 'Hoxton Mix Certification.pdf'

    // Append the link to the document body
    document.body.appendChild(link)

    // Programmatically click the link to trigger the download
    link.click()

    // Remove the link from the document
    document.body.removeChild(link)

    // Revoke the object URL to free memory
    URL.revokeObjectURL(url)
  } catch (error) {
    console.error('Error downloading the PDF:', error)
  }
}
const OwnersPage: FC<OwnersPageType> = ({}) => {
  useNotLoggedInRedirect()
  const [downloadingPDF, setDownloadingPDF] = useState(false)
  const { feature_letter_of_certification } = useFlags([
    'feature_letter_of_certification',
  ])
  const { subscriptionDetail } = useDefaultSubscription()
  const company = subscriptionDetail?.company
  const needsVerify = kycNeedsVerifying(subscriptionDetail)
  return (
    <main className='container-fluid'>
      <Head>
        <title>{Project.siteName} | Business Settings</title>
      </Head>
      <div className='flex-row mb-4 mt-2 align-items-center'>
        <div className='flex-fill'>
          <h1 className='d-block mb-0'>Business Settings</h1>
        </div>
        {feature_letter_of_certification.enabled && !needsVerify && (
          <div className='text-end mb-4'>
            <Button
              disabled={downloadingPDF || !subscriptionDetail}
              onClick={() => {
                if (!subscriptionDetail) return
                setDownloadingPDF(true)
                downloadPDF(`${subscriptionDetail.id}`).finally(() => {
                  setDownloadingPDF(false)
                })
              }}
            >
              Generate Letter of Certification
            </Button>
          </div>
        )}
      </div>

      {!company ? (
        <div className='text-center'>
          <Loader />
        </div>
      ) : (
        <Card
          headerClassName='bg-tertiary'
          header={
            needsVerify && (
              <div className='flex-row align-items-center'>
                <div className='text-white flex-fill'>
                  <Icon name='company' />
                  <span className='ms-1 text-xs fw-bold'>
                    Your company is not verified
                  </span>
                  <div className='mt-1 text-xs'>
                    Please review your company information and submit any
                    required documents as soon as possible.
                  </div>
                </div>
                {subscriptionDetail?.kyc_status?.kyc_link ? (
                  <a
                    target='_blank'
                    href={subscriptionDetail?.kyc_status?.kyc_link}
                    className='px-10 btn btn-outline-light'
                    rel='noreferrer'
                  >
                    Verify
                  </a>
                ) : (
                  <Link
                    href={'/business/owners'}
                    className='px-10 btn btn-outline-light'
                    rel='noreferrer'
                  >
                    Verify
                  </Link>
                )}
              </div>
            )
          }
        >
          {
            <>
              <h1 className='mb-6 fs-display-3'>
                {company.legal_name || company.trading_name}
              </h1>
              <TitleValue
                className='mb-3'
                title='Legal Name'
                value={company.legal_name}
              />
              <TitleValue
                className='mb-3'
                title='Trading Name'
                value={company.trading_name}
              />
              {Project.locale === 'en-gb' && (
                <TitleValue
                  className='mb-3'
                  title='Company Number'
                  value={
                    !!company.company_number && `#${company.company_number}`
                  }
                />
              )}
              {Project.locale === 'en-au' && (
                <>
                  <TitleValue
                    className='col-md-4 mb-5'
                    title='Australian Business Number'
                    value={!!company.abn_number && `#${company.abn_number}`}
                  />
                  <TitleValue
                    className='col-md-4 mb-5'
                    title=' Australian Company Number'
                    value={!!company.acn_number && `#${company.acn_number}`}
                  />
                </>
              )}
              <TitleValue
                className='mb-3'
                title='Formation'
                value={
                  !!company.formation &&
                  `${dayjs(company.formation, 'YYYY-MM-DD').format(
                    'Do MMM YYYY',
                  )}`
                }
              />

              <TitleValue
                className='mb-3'
                title='Business Type'
                value={
                  (!!company.business_type &&
                    BusinessTypeData?.find(
                      (v) => v.value === company.business_type,
                    )?.label) ||
                  company.business_type ||
                  null
                }
              />
              <TitleValue
                className='mb-3'
                title='Country of Incorporation'
                value={
                  company.country_of_corporation &&
                  countries[company.country_of_corporation]
                }
              />
              <hr className='mt-0' />
              <TitleValue
                className='col-md-4'
                title='What does your business do?'
                value={company.nature_of_business}
              />
            </>
          }
        </Card>
      )}
    </main>
  )
}

export default OwnersPage
