import { useEffect, useState } from 'react'
import { useUser } from 'common/services/useUser'
import { getApi } from 'common/api'
import { Res } from 'common/types/responses'
import { useHydrated } from 'common/services/useHydrated'

export default function useNotLoggedInRedirect(): {
  user?: Res['user']
  isReady: boolean
} {
  const { data: user, isLoading } = useUser({})
  const hydrated = useHydrated()
  const [isReady, setIsReady] = useState<boolean>(!!user)
  useEffect(() => {
    if (!isLoading && !user?.username && typeof window !== 'undefined') {
      getApi().logoutRedirect?.()
    } else if (hydrated) {
      setIsReady(true)
    }
  }, [user, isReady])
  return {
    isReady,
    user,
  }
}
