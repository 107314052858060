import cn from 'classnames'
import React, { FC, InputHTMLAttributes, useRef, ChangeEvent } from 'react'

import Icon from 'project/Icon'
import { safeParseEventValue } from 'common/utils/forms/safeParseEventValue'

type CheckboxProps = InputHTMLAttributes<HTMLInputElement> & {
  label?: string
  id: string
  checked: boolean
  onChange?: (newValue: boolean) => void
}

const Checkbox: FC<CheckboxProps> = ({
  checked,
  className,
  id,
  label,
  onChange,
  ...rest
}) => {
  const checkboxRef = useRef<HTMLInputElement>(null)

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    onChange?.(safeParseEventValue(e))
  }

  return (
    <div
      className={cn(
        'lh-input d-flex align-items-center user-select-none',
        className,
      )}
    >
      <input
        {...rest}
        ref={checkboxRef}
        type='checkbox'
        checked={checked}
        onChange={handleChange}
        id={id}
        className='d-none'
      />
      {checked ? (
        <svg
          width='21'
          height='20'
          viewBox='0 0 21 20'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M10.5 20C4.977 20 0.5 15.523 0.5 10C0.5 4.477 4.977 0 10.5 0C16.023 0 20.5 4.477 20.5 10C20.5 15.523 16.023 20 10.5 20Z'
            fill='#1977EC'
          />
          <path
            d='M8.743 14.485L4.5 10.242L5.914 8.828L8.743 11.657L14.399 6L15.814 7.414L8.743 14.485Z'
            fill='white'
          />
        </svg>
      ) : (
        <svg
          width='21'
          height='20'
          viewBox='0 0 21 20'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M10.5 20C4.977 20 0.5 15.523 0.5 10C0.5 4.477 4.977 0 10.5 0C16.023 0 20.5 4.477 20.5 10C20.5 15.523 16.023 20 10.5 20ZM10.5 18C12.6217 18 14.6566 17.1571 16.1569 15.6569C17.6571 14.1566 18.5 12.1217 18.5 10C18.5 7.87827 17.6571 5.84344 16.1569 4.34315C14.6566 2.84285 12.6217 2 10.5 2C8.37827 2 6.34344 2.84285 4.84315 4.34315C3.34285 5.84344 2.5 7.87827 2.5 10C2.5 12.1217 3.34285 14.1566 4.84315 15.6569C6.34344 17.1571 8.37827 18 10.5 18Z'
            fill='#6E6E73'
          />
        </svg>
      )}
      {label && (
        <label className='form-check-label' htmlFor={id}>
          {label}
        </label>
      )}
    </div>
  )
}

Checkbox.displayName = 'Checkbox'
export default Checkbox
