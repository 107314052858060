import { useDefaultSubscription } from 'common/services/useDefaultSubscription'
import { FC, useEffect, useState } from 'react'
import useSubscriptions from 'common/useSubscriptions'
import { useGetUserQuery, useLogoutMutation } from 'common/services/useUser'
import Icon from 'project/Icon'
import { useRouter } from 'next/router'
import Dropdown from './base/forms/Dropdown'
import DropdownItem from './base/forms/DropdownItem'
import SelectIcon from './base/SelectIcon'
import ModalDefault from './base/ModalDefault'
import cognito from 'common/cognito'
import Link from 'next/link'
import ProfileIcon from './ProfileIcon'

type SubscriptionSelectType = {}

const SubscriptionSelect: FC<SubscriptionSelectType> = ({}) => {
  const { setDefaultSubscriptionId, subscriptionDetail } =
    useDefaultSubscription()
  const router = useRouter()
  const subscriptions = useSubscriptions()
  const { data: user } = useGetUserQuery({})
  const [logout] = useLogoutMutation()
  const [show, setShow] = useState(false)
  const [showAccount, setShowAccount] = useState(false)

  const handleSelect = (id: string) => {
    if (router.query.subscriptionId) {
      router.replace('/')
    }
    setDefaultSubscriptionId(id)
    setShow(false)
    setShowAccount(false)
    // API.loginRedirect(user, id)
  }

  useEffect(() => {
    const handleRouteChange = () => {
      setShow(false)
      setShowAccount(false)
    }
    router.events.on('routeChangeStart', handleRouteChange)
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [])

  return (
    <>
      <div
        onClick={() => setShowAccount(true)}
        className='cursor-pointer d-flex gap-2 align-items-center '
      >
        <div>
          <h5 className='fw-semibold mb-0'>
            {subscriptionDetail?.company_name}
          </h5>
          <div className='text-muted'>{user?.username}</div>
        </div>
        <ProfileIcon />
      </div>
      <ModalDefault
        isOpen={showAccount}
        onDismiss={() => setShowAccount(false)}
        toggle={() => setShowAccount(!showAccount)}
      >
        <div style={{ minHeight: 450 }} className='py-4 px-6'>
          <h2>Your Account</h2>
          <Dropdown
            show={show}
            setShow={setShow}
            dropdownContent={
              <div className='overflow-auto w-100' style={{ maxHeight: 300 }}>
                {subscriptions?.map((subscription) => (
                  <DropdownItem
                    onClick={() => handleSelect(subscription.id as any)}
                    key={subscription.id}
                    className='dropdown-item'
                  >
                    <div
                      className='flex-row align-items-center'
                      key={subscription.id}
                    >
                      <div className='d-flex me-2 flex-fill'>
                        <span className='text-nowrap'>
                          {subscription.display_name}
                        </span>
                      </div>
                      <Icon name='chevron-right' fill='#a9b5c0' />
                    </div>
                  </DropdownItem>
                ))}
              </div>
            }
          >
            <div
              onClick={() => setShow(!show)}
              className='d-flex align-items-center cursor-pointer rounded outline px-4 py-2 justify-content-between mt-6'
            >
              <div className=' user-select-none d-flex gap-2 align-items-center '>
                <div>
                  <h5 className='fw-semibold mb-0'>
                    {subscriptionDetail?.company_name}
                  </h5>
                  <div className='text-muted'>{user?.username}</div>
                </div>
                <ProfileIcon />
              </div>
              <SelectIcon />
            </div>
          </Dropdown>
          <Link
            href='/account'
            className='d-flex text-body align-items-center gap-2 mt-5 fw-semibold cursor-pointer'
          >
            <Icon fill='body' name='user-settings' />
            My Account
          </Link>
          <div
            className='d-flex align-items-center gap-2 mt-5 fw-semibold cursor-pointer'
            onClick={() => {
              router.replace('/')
              cognito.logout().finally(() => {
                logout({})
              })
            }}
          >
            <Icon fill='body' name='logout' />
            Logout
          </div>
        </div>
      </ModalDefault>
    </>
  )

  if (!subscriptions || subscriptions?.length <= 1) {
    return (
      <div className={'d-flex flex-column'}>
        <div className='fw-bold'>{subscriptions?.[0]?.display_name}</div>
        <div>
          {user?.username} ({subscriptions?.[0]?.name})
        </div>
      </div>
    )
  }

  return <div className='nav-item w-100 dropdown'></div>
}

export default SubscriptionSelect
