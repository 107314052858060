import React, { FC } from 'react'
import QRCode from 'react-qr-code'

import Card from 'components/base/Card'
import Link from 'next/link'
import { Project } from 'common/project'

interface Props {
  className?: string
}
const MobileAppCard: FC<Props> = ({ className }) => {
  if (!Project.appStore && !Project.playStore) {
    return null
  }
  return (
    <div className={className}>
      <Card
        padding='none'
        className={'mobile-app-card px-7 py-5 position-relative'}
      >
        <h1 className='text-white mb-1 hero-title'>
          ACCESS YOUR MAIL ON THE MOVE
        </h1>
        <h1 className='text-white fw-semibold'>
          Download the
          <br /> Hoxton Mix App
        </h1>
        <div
          className='p-3 bg-white rounded-lg mt-2 d-none d-lg-block'
          style={{
            width: 'fit-content',
          }}
        >
          <QRCode
            size={110}
            value={' https://www.hoxtonmix.com/api/download-app'}
          />
        </div>
        <div className='d-flex mt-2 d-lg-none'>
          {Project.appStore && (
            <Link target={'_blank'} href={Project.appStore}>
              <img
                loading='lazy'
                alt={'Apple Icon'}
                height={40}
                src={'/images/Apple.svg'}
                className={'me-3'}
              />
            </Link>
          )}
          {Project.playStore && (
            <Link target={'_blank'} href={Project.playStore}>
              <img
                loading='lazy'
                alt={'Google Icon'}
                height={40}
                src={'/images/Google.svg'}
              />
            </Link>
          )}
        </div>
        <img
          className='position-absolute bottom-0 end-0 hero-image d-none d-sm-block'
          src='/images/hm-app-plug-phone.png'
          alt='hm-app-plug-phone'
        />
      </Card>
    </div>
  )
}

export default MobileAppCard
