// @ts-ignore
import { CategoryName, Permission } from 'common/types/responses'

const DEV = global.__DEV__
  ? // @ts-ignore
    global.__DEV__
  : // @ts-ignore
  typeof __DEV__ === 'undefined'
  ? false
  : // @ts-ignore
    __DEV__

export const Constants = {
  E2E: DEV && false,
  E2E_NAMESPACE: null,
  REQUEST_TIMEOUT: 10000,
  VAT: 0.2,
  defaultLocale: 'en-gb',
  enableSentry: !__DEV__,
  events: {},
  mailTags: {
    'Clients & Customers': '#D38A5E',
    'Financial': '#5463FF',
    'Government & Public Sector': '#4AA96C',
    'Human Resources': '#7C4DFF',
    'Industry Updates': '#ADE8F4',
    'Marketing & Advertising': '#A6E1FA',
    'Official / Legal': '#C5A3FF',
    'Suppliers & Vendors': '#40A8C4',
    'Taxation': '#2F7164',
    'Technology & IT': '#3A5A99',
    'Utilities & Services': '#9294F3',
  } as Record<CategoryName, string>,
  simulate: !__DEV__
    ? {}
    : {
        SKIP_KYC: true,
      },
}
