import { FC, useEffect, useState } from 'react'
import { useGetUserQuery } from 'common/services/useUser'
import { useHydrated } from 'common/services/useHydrated'
import { getApi } from 'common/api'
import Loader from './base/Loader'
import { setProject } from 'common/project'
import { useDevSettings } from 'common/services/useDevSettings'

type ProfileContainerType = {}
const ProfileContainer: FC<ProfileContainerType> = ({ children }) => {
  const { data } = useGetUserQuery({}, { skip: getApi().getToken?.() })
  const { devSettings, setDevSettings } = useDevSettings()
  if (devSettings.project) {
    setProject(devSettings.project)
  }
  const hydrated = useHydrated()
  const [ready, setReady] = useState(!!devSettings?.whitelabel)
  //If there's a whitelabel add it to the body as a class
  if (devSettings.whitelabel) {
    document.body.classList.forEach((className) => {
      if (className.startsWith('w-')) {
        document.body.classList.remove(className)
      }
    })
    document.body.classList.add(`w-${devSettings.whitelabel}`)
  }

  useEffect(() => {
    if (ready) {
      return
    }
    //fetch and set whitelabel before showing any ui
    fetch(
      `/api/config?href=${encodeURIComponent(document.location.href)}`,
    ).then(async (res) => {
      const whitelabel = await res.json()
      let newProject
      if (whitelabel.whitelabel) {
        newProject = await import(
          `common/env/project_${whitelabel.env === 'dev' ? 'dev' : 'prod'}_${
            whitelabel.whitelabel
          }-portal.js`
        )
        setProject(newProject.Project)
      }
      setDevSettings({
        ...devSettings,
        environment: whitelabel.env === 'dev' ? 'staging' : 'production',
        project: newProject?.Project,
        whitelabel: whitelabel.whitelabel,
      })

      setReady(true)
    })
  }, [devSettings.whitelabel, ready])
  if (!hydrated || !ready)
    return (
      <div className='text-center vh-100 d-flex align-items-center justify-content-center'>
        <Loader />
      </div>
    )
  return <>{typeof children === 'function' ? children(data) : children}</>
}

export default ProfileContainer
