import { FC, HTMLProps, ReactNode } from 'react'
import classNames from 'classnames'

type CardProps = HTMLProps<HTMLDivElement> & {
  title?: string
  padding?: 'sm' | 'xs' | 'none'
  footer?: ReactNode
  footerClassName?: string
}

const Card: FC<CardProps> = ({
  children,
  className,
  footer,
  footerClassName,
  padding,
  title,
  ...rest
}) => {
  return (
    <div
      {...rest}
      className={classNames(
        'bg-card d-flex flex-column position-relative shadow-2 rounded-lg',
        'h-100',
        className,
      )}
    >
      <div
        className={classNames({
          'flex-1 d-flex flex-column': true,
          'px-0 py-0': padding === 'none',
          'px-1 py-1': padding === 'xs',
          'px-2 py-2': padding === 'sm',
          'px-5 py-6': !padding,
        })}
      >
        {!!title && <h3>{title}</h3>}
        {children}
      </div>

      {footer && (
        <div
          className={classNames(
            'card-footer',
            footerClassName,
            'rounded-bottom',
            padding && `p-${padding}`,
          )}
        >
          {footer}
        </div>
      )}
    </div>
  )
}

export default Card
