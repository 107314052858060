import Link from 'next/link'
import { useRouter } from 'next/router'
import { Children, cloneElement, FC } from 'react'
import classNames from 'classnames'

export interface ActiveLinkType {
  href?: string
  match?: any
  as?: string
  activeClassName?: string
  className?: string
}

const ActiveLink: FC<ActiveLinkType> = ({
  href = '',
  match,
  as = href,
  activeClassName = 'active',
  className,
  children,
  ...rest
}) => {
  const router = useRouter()
  // @ts-ignore
  let isActive = false
  if (
    (router.asPath === href && activeClassName) ||
    (match && (router.asPath || router.pathname).match(match))
  ) {
    isActive = true
  }

  return (
    <Link
      className={classNames(className, isActive ? activeClassName : '')}
      as={as}
      href={href}
      {...rest}
    >
      {/*@ts-ignore*/}
      {children}
    </Link>
  )
}

export default ActiveLink
