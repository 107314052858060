import { omit } from 'common/utils/array/omit'

export function fromParam(url?: string): Record<string, any> {
  // {min:100,max:200} <- ?min=100&max=200
  const documentSearch =
    typeof document === 'undefined' ? '' : document.location.search
  let str
  if (url) {
    const split = url.split('/')
    str = split[split.length - 1]
  }

  if (!str && !documentSearch) {
    return {}
  }
  // eslint-disable-next-line
const urlString = (str || documentSearch).replace(/(^\?)/, '');
  try {
    const params = new URLSearchParams(urlString)
    const entries = params.entries() //returns an iterator of decoded [key,value] tuples
    const result: Record<string, string> = {}
    for (const [key, value] of entries) {
      // each 'entry' is a [key, value] tupple
      result[key] = value
    }
    return result
  } catch (e) {
    return {}
  }
}

export function toParam(obj?: Record<string, any>): string {
  // {min:100,max:200} -> ?min=100&max=200
  if (!obj) return ''
  const allDefined = omit(
    obj,
    Object.keys(obj).filter(
      (key) => typeof obj[key] === 'undefined' || obj[key] === null,
    ),
  )
  return Object.keys(allDefined)
    .map((k) => {
      // @ts-ignore
      let val = allDefined[k]
      if (Array.isArray('array')) {
        val = val.join(',')
      }
      return `${encodeURIComponent(k)}=${encodeURIComponent(val)}`
    })
    .join('&')
}
