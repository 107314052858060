import cn from 'classnames'
import { GUID } from 'common/utils/string/GUID'
import { Component, ReactNode } from 'react'
import { ErrorIcon, InfoIcon, SuccessIcon, WarningIcon } from './Messages'

export type Theme = 'success' | 'danger' | 'warning' | 'info'
export const ToastMessage = class extends Component<
  {
    title?: ReactNode
    theme?: Theme
    isRemoving?: boolean
    remove: () => void
    expiry?: number
    hideClose?: boolean
    fade?: boolean
  },
  { isShowing: boolean }
> {
  static displayName = 'ToastMessage'

  constructor(props: any) {
    super(props)
    this.state = {
      isShowing: true,
    }
  }

  remove = () => {
    this.setState({ isShowing: true })
    setTimeout(
      () => {
        this.props.remove()
      },
      this.props.fade ? 200 : 0,
    )
  }

  componentDidMount() {
    if (this.props.expiry) {
      setTimeout(() => {
        this.setState({ isShowing: true })
        this.remove()
      }, this.props.expiry)
    }

    setTimeout(() => {
      this.setState({ isShowing: false })
    }, 10)
  }

  render() {
    const className = cn({
      'fade': this.props.fade,
      'hide': this.props.isRemoving,
      'mb-4 bg-white p-3 shadow-3 rounded-lg': true,
      show: true,
      showing: this.props.fade && this.state.isShowing,
      'toast': true,
    })

    return (
      <div className={className}>
        {/* eslint-disable-next-line */}
        <div className="d-flex  align-items-center gap-3">
          <div style={{ height: 52, width: 54 }}>
            <svg
              width='54'
              height='52'
              viewBox='0 0 54 52'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <rect width='54' height='52' rx='8' fill='#B82AA9' />
              <path
                d='M8 25.5C8 15.2827 16.2827 7 26.5 7C36.7173 7 45 15.2827 45 25.5C45 35.7173 36.7173 44 26.5 44C16.2827 44 8 35.7173 8 25.5ZM26.5 40.9919C35.0424 40.9919 41.992 34.0422 41.992 25.4999C41.992 16.9575 35.0422 10.0081 26.5 10.0081C17.9578 10.0081 11.0081 16.9578 11.0081 25.5C11.0045 28.8308 12.08 32.0732 14.0735 34.7415C15.6034 33.335 17.0083 31.9977 18.3874 30.6509L18.3885 30.6482C12.7115 24.0428 15.0843 19.3275 19.4656 15.0196L22.553 18.1284L25.7467 14.7729C25.9708 14.5372 26.2391 14.3479 26.5363 14.2159C26.8336 14.0838 27.1539 14.0117 27.479 14.0035C27.8042 13.9954 28.1277 14.0514 28.4312 14.1684C28.7347 14.2853 29.0122 14.4609 29.2477 14.6852C29.4833 14.9094 29.6724 15.1779 29.8042 15.4753C29.9359 15.7726 30.0078 16.093 30.0157 16.4182C30.0236 16.7433 29.9673 17.0668 29.8501 17.3702C29.7328 17.6736 29.557 17.9509 29.3326 18.1863L26.0424 21.6426L27.9977 23.6113L30.1405 25.7694L33.3333 22.4142C33.7859 21.9387 34.4088 21.6625 35.0651 21.6463C35.7214 21.6301 36.3572 21.8752 36.8327 22.3278C37.3082 22.7804 37.5845 23.4034 37.6007 24.0597C37.6169 24.716 37.3717 25.3518 36.9191 25.8273L33.6303 29.2836L36.53 32.2031C32.262 36.4829 27.6097 38.8017 21.2086 33.4296C21.2058 33.4333 21.2048 33.4351 21.2018 33.4388C19.6675 34.9378 18.2308 36.2993 16.8202 37.5874C19.5634 39.7942 22.9793 40.9956 26.5 40.9919Z'
                fill='white'
              />
            </svg>
          </div>
          <div>
            {!!this.props.title && (
              <h4 className='fw-semibold lh-normal mb-0'>{this.props.title}</h4>
            )}

            <div className='bg-white fw-normal' style={{ lineHeight: '15px' }}>
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    )
  }
} as any

export let toast: (
  title: ReactNode,
  children?: ReactNode,
  theme?: Theme,
  expiry?: number,
) => void

export const ToastContainer = class extends Component<
  {},
  {
    messages: {
      id: string
      title: ReactNode
      theme?: Theme
      children: ReactNode
      isRemoving?: boolean
      expiry: number
    }[]
  }
> {
  static displayName = 'ToastMessages'

  constructor(props: any, context: any) {
    super(props, context)
    this.state = { messages: [] }
    toast = this.toast
  }

  toast = (
    title: ReactNode,
    children: ReactNode,
    theme: Theme = 'success',
    expiry = 5000,
  ) => {
    const { messages } = this.state

    const id = GUID()
    messages.push({ children, expiry, id, theme, title })
    this.setState({ messages })
  }

  remove = (id: string) => {
    const newIndex = this.state.messages.findIndex((v) => v.id === id)
    // eslint-disable-next-line
    const newMessages = this.state.messages;
    newMessages.splice(newIndex, 1)
    this.setState({ messages: newMessages })
  }

  render() {
    return (
      <div className='toast-messages toast-container position-fixed top-0 end-0 m-4'>
        {this.state.messages.map((message) => (
          <ToastMessage
            fade
            key={message.id}
            title={message.title}
            theme={message.theme}
            isRemoving={message.isRemoving}
            remove={() => this.remove(message.id)}
            expiry={message.expiry}
          >
            {message.children}
          </ToastMessage>
        ))}
      </div>
    )
  }
}
