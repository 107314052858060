import { Company, Res } from "common/types/responses";
import { Req } from 'common/types/requests'
import { service } from 'common/service'
import { getStore } from 'common/store'
import { getKycStatus } from './useKycStatus'
import { userService } from "./useUser";
import { cloneDeep } from "lodash";

export const companyService = service
  .enhanceEndpoints({ addTagTypes: ['Company'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getCompanies: builder.query<Res['companies'], Req['getCompanies']>({
        providesTags: [{ id: 'LIST', type: 'Company' }],

        queryFn: async (args, api, _extraOptions, baseQuery) => {
          const state = api.getState();
          const userCompanies = userService.endpoints.getUser.select({})(state)?.data?.companies
          //If user has companies, use that, otherwise use the companies endpoint
          const response = userCompanies ||
            (await baseQuery({
            url: '/customers/companies',
            method: 'GET',
          }));
          if(response.error) {
            return {error:response.error}
          }
          let res = cloneDeep(response||[])
          await Promise.all(
            res.map(async (c:Company, i:number) => {
              const [detail] = await Promise.all([
                getCompany(getStore(), {
                  id: c.id,
                }),
              ])
              const subscriptions = await Promise.all(
                c.subscriptions.map(async (v) => {
                  const kycResponse = await getKycStatus(getStore(), {
                    id: c.id,
                    subscription_id: v.id,
                  })
                  return {
                    ...v,
                    kyc_status: kycResponse.data,
                  }
                }),
              )
              res[i] = {
                ...res[i],
                ...detail.data,
                subscriptions,
              }
            }),
          )
          return { data:res }
        },
      }),
      getCompany: builder.query<Res['company'], Req['getCompany']>({
        providesTags: (a, _, q) => [{ id: q?.id, type: 'Company' }],
        query: (q) => ({
          url: `customers/companies/${q.id}`,
        }),
        // queryFn: (q) => ({
        //   data: responses.company,
        // }),
      }),
      // END OF ENDPOINTS
    }),
  })

export async function getCompanies(
  store: any,
  data: Req['getCompanies'],
  options?: Parameters<
    typeof companyService.endpoints.getCompanies.initiate
  >[1],
) {
  return store.dispatch(
    companyService.endpoints.getCompanies.initiate(data, options),
  )
}
export async function getCompany(
  store: any,
  data: Req['getCompany'],
  options?: Parameters<typeof companyService.endpoints.getCompany.initiate>[1],
) {
  return store.dispatch(
    companyService.endpoints.getCompany.initiate(data, options),
  )
}
// END OF FUNCTION_EXPORTS

export const {
  useGetCompaniesQuery,
  useGetCompanyQuery,
  // END OF EXPORTS
} = companyService

/* Usage examples:
const { data, isLoading } = useGetCompaniesQuery({ id: 2 }, {}) //get hook
const [createCompanies, { isLoading, data, isSuccess }] = useCreateCompaniesMutation() //create hook
companyService.endpoints.getCompanies.select({id: 2})(store.getState()) //access data from any function
*/
