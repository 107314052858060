import Input from './Input'
import { FC, ReactNode, useRef } from 'react'
import classNames from 'classnames'
import { IconName } from 'project/Icon'
import { GUID } from 'common/utils/string/GUID'

export interface InputGroup {
  inputProps?: {
    name?: string
    id?: string
    error?: string
    readOnly?: boolean
    maxLength?: number
    'data-test'?: string
  }
  className?: string
  title?: string
  component?: ReactNode
  textarea?: boolean
  isValid?: boolean
  required?: boolean
  id?: string
  onBlur?: (e: FocusEvent) => void
  disabled?: boolean
  errorMessage?: string
  name?: string
  value?: string
  defaultValue?: string
  onChange?: (e: any) => void
  type?: string
  placeholder?: string
  iconColour?: string
  icon?: IconName
  iconLeft?: IconName
  touched?: boolean
}

const InputGroup: FC<InputGroup> = ({
  className,
  component,
  defaultValue,
  disabled,
  errorMessage,
  iconColour,
  id: _id,
  inputProps = {},
  isValid,
  name,
  onBlur,
  onChange,
  placeholder,
  required,
  textarea,
  title,
  touched,
  type,
  value,
}) => {
  //   Should I use useRef to connect focus with input?
  //   const focus = () => {
  //     input.focus();
  //   };

  const id = _id || inputProps.id || inputProps.name || GUID()
  return (
    <div className={classNames(`form-group`, className)}>
      {title ? (
        <label className='form-label body-secondary' htmlFor={id}>
          {title}
          {required && <span className=''>*</span>}
        </label>
      ) : null}

      {errorMessage && (
        <span>
          <span
            id={inputProps.name ? `${inputProps.name}-error` : ''}
            className='text-danger ms-1'
          >
            {errorMessage}
          </span>
        </span>
      )}

      <>
        {component ? (
          component
        ) : (
          <Input
            name={name}
            textarea={textarea}
            onBlur={onBlur}
            isValid={isValid}
            disabled={disabled}
            value={value}
            defaultValue={defaultValue}
            onChange={onChange}
            type={type || 'text'}
            id={id}
            placeholder={placeholder}
            iconColour={iconColour}
            touched={touched}
            {...(inputProps || {})}
          />
        )}
      </>
    </div>
  )
}

InputGroup.displayName = 'InputGroup'
export default InputGroup
