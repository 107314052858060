import { PagedResponse } from 'common/types/responses'
import { PagedRequest } from 'common/types/requests'

export default function (res: PagedResponse<any>, query: PagedRequest<any>) {
  return {
    ...res,
    currentPage: query.page,
    totalPages: Math.ceil(res.count / query.page_size),
  } as PagedResponse<any>
}
