import { FC, lazy, Suspense, useEffect, useMemo } from 'react'
import { useDevSettings } from 'common/services/useDevSettings'

type LogoType = {
  width?: number
  collapsed?: boolean
  height?: number
}

const Logo: FC<LogoType> = ({ height = 40, width = 40, ...rest }) => {
  const { devSettings } = useDevSettings()
  const whitelabel = devSettings.whitelabel

  // Memoize the dynamically imported component
  const DynamicLogo = useMemo(() => {
    return lazy(() =>
      import(`./${whitelabel || 'default'}`).catch(() => import(`./default`)),
    )
  }, [whitelabel])

  return (
    <Suspense fallback={null}>
      <DynamicLogo height={height} {...rest} />
    </Suspense>
  )
}

export default Logo
