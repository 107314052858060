import { Address } from 'common/types/requests'

export function validateAddress(address: Address | null) {
  return (
    !!address?.address_line_1 &&
    !!address?.postal_code &&
    !!address?.country &&
    !!address?.city
  )
}
