import { NextPageWithLayout } from '../../types/nextPageWithLayout'
import Login from 'components/project/login/Login'

const LoginPage: NextPageWithLayout<{}> = () => {
  return (
    <div className='d-flex flex-column justify-content-center align-items-center bg-light-300 vw-100 vh-100'>
      <Login canClose />
    </div>
  )
}

LoginPage.getLayout = (page) => <>{page}</>

export default LoginPage
