import { FC, ReactNode } from 'react'

type CardTitleType = {
  title: ReactNode
  subtitle: ReactNode
}

const CardTitle: FC<CardTitleType> = ({ subtitle, title }) => {
  return (
    <>
      <div className='text-muted fs-xs mb-3 fw-semibold'>{title}</div>
      <h1 className='fs-display-2 mb-4 fw-semibold'>{subtitle}</h1>
    </>
  )
}

export default CardTitle
