import React, { FC, FormEvent, useEffect, useState } from 'react'
import { isValidPhoneNumber } from 'libphonenumber-js'
import {
  useGetPhonesQuery,
  useUpdatePhoneMutation,
} from 'common/services/usePhone'
import { toast } from 'components/base/Toast'
import { PhoneDetails } from 'common/types/responses'
import Card from 'components/base/Card'
import Button from 'components/base/forms/Button'
import ModalDefault, { ModalTitle } from 'components/base/ModalDefault'
import { ErrorMessage } from 'components/base/Messages'
import InputGroup from 'components/base/forms/InputGroup'
import PhoneInput from 'components/base/PhoneInput'
import { safeParseEventValue } from 'common/utils/forms/safeParseEventValue'
import CardTitle from './CardTitle'

type ManagePhoneCardProps = {
  className?: string
  company_id: string
}

const ManagePhoneCard: React.FC<ManagePhoneCardProps> = ({
  className,
  company_id,
}) => {
  const { data } = useGetPhonesQuery(
    { id: `${company_id}` },
    { skip: !company_id },
  )

  if (!data?.length) {
    return null
  }
  return (
    <>
      {data?.map((phone) => (
        <PhoneCardInner
          key={phone.phone_id}
          className={className}
          company_id={company_id}
          phone={phone}
        />
      ))}
    </>
  )
}

type PhoneCardInnerType = {
  company_id: string
  className?: string
  phone: PhoneDetails
}

const PhoneCardInner: FC<PhoneCardInnerType> = ({
  className,
  company_id,
  phone,
}) => {
  const [showEditNumber, setShowEditNumber] = useState(false)
  const [forwardingNumber, setForwardingNumber] = useState('')
  const [updatePhone, { error, isLoading: updating }] = useUpdatePhoneMutation(
    {},
  )

  useEffect(() => {
    setForwardingNumber(phone?.contact_number || '')
  }, [phone])
  const invalid = !isValidPhoneNumber(forwardingNumber) || updating
  useEffect(() => {
    if (showEditNumber) {
      setTimeout(() => {
        document.getElementById('forwarding-number')?.focus()
      })
    }
  }, [showEditNumber])
  const save = (e: FormEvent) => {
    e?.preventDefault?.()
    if (invalid) {
      return
    }
    if (phone) {
      updatePhone({
        ...phone,
        company_id: company_id,
        contact_number: forwardingNumber,
      }).then((res) => {
        if (!res.error) {
          toast('Success', 'Updated phone number')
          setShowEditNumber(false)
        }
      })
    }
  }
  return (
    <div className={className}>
      <Card
        footer={
          <div className='flex-row align-items-center'>
            <div className='flex-fill text-sm'>
              <div className='fw-bold ms-2'>Forwarding number</div>
              <div className=' ms-2'>{phone.contact_number}</div>
            </div>
            <Button
              onClick={() => setShowEditNumber(true)}
              theme='lightOutline'
            >
              Manage
            </Button>
          </div>
        }
        footerClassName='text-white p-5 bg-phone'
      >
        <div className='flex-fill'>
          <CardTitle title={'PHONE'} subtitle={'Virtual Office Phone'} />
          <div className='mt-2'>
            <div className='fs-lg'>Your Business Phone Number</div>
            <h3 className='fw-bold'>{phone.number}</h3>
          </div>
        </div>
      </Card>
      <ModalDefault
        isOpen={showEditNumber}
        onDismiss={() => setShowEditNumber(false)}
        toggle={() => setShowEditNumber(!showEditNumber)}
      >
        <ModalTitle
          title={`Edit Forwarding Number`}
          onDismiss={() => setShowEditNumber(false)}
        />
        <ErrorMessage>{error}</ErrorMessage>
        <form onSubmit={save}>
          <InputGroup
            title='Forwarding Number'
            component={
              <PhoneInput
                onEnterKeyPress={save}
                id='forwarding-number'
                value={forwardingNumber}
                onChange={(e) => {
                  setForwardingNumber(safeParseEventValue(e))
                }}
              />
            }
          />
          <div className='text-end mt-4'>
            <Button disabled={invalid} type='submit'>
              Save
            </Button>
          </div>
        </form>
      </ModalDefault>
    </div>
  )
}

export default ManagePhoneCard
