import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import updateLocale from 'dayjs/plugin/updateLocale'
import customFormat from 'dayjs/plugin/customParseFormat'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(customFormat)
dayjs.extend(advancedFormat)
dayjs.extend(updateLocale)
dayjs.extend(relativeTime)
dayjs.updateLocale('en', {
  weekStart: 1,
})
