import { FC, useEffect, useState } from 'react'

import { Project } from 'common/project'
import Button from 'components/base/forms/Button'
import InputGroup from 'components/base/forms/InputGroup'
import { useLoginMutation, useUser } from 'common/services/useUser'
import Card from 'components/base/Card'
import { ErrorMessage } from 'components/base/Messages'
import { useRouter } from 'next/router'
import Loader from 'components/base/Loader'
import { useDefaultSubscription } from 'common/services/useDefaultSubscription'
import { fromParam } from 'common/utils/urls/param'
import Logo from 'components/Logo'
import { useDevSettings } from 'common/services/useDevSettings'

interface LoginProps {
  canClose?: boolean
}

const Login: FC<LoginProps> = ({ canClose }) => {
  const router = useRouter()
  const [login, { data, error, isLoading }] = useLoginMutation()
  const { devSettings } = useDevSettings()
  const [email, setEmail] = useState('')
  const { isLoading: userLoading } = useUser({})
  const { setDefaultSubscriptionId } = useDefaultSubscription()
  const [awaitingVerification, setAwatingVerification] = useState(false)
  useEffect(() => {
    if (router.query.username) {
      setEmail(decodeURIComponent(`${router.query.username}`))
      router.replace(router.pathname, router.pathname, { shallow: true })
    }
  }, [router.query])
  const handleSubmit = (event: any) => {
    event.preventDefault()
    if (!!email && email !== '') {
      const redirect = router.query.redirect
      setDefaultSubscriptionId(null)
      login({
        email,
        link_url: `${document.location.origin}${
          Project.redirectSignIn
        }?username=${email}${
          router.query.redirect
            ? `&redirect=${encodeURIComponent(redirect)}`
            : ''
        }`,
      }).then((res) => {
        if ('error' in res) {
        } else {
          setAwatingVerification(true)
        }
      })
    }
  }

  const params =
    typeof document === 'undefined'
      ? {}
      : fromParam(document.location.href.split('?')[1])

  return (
    <Card className='h-auto login-form'>
      {userLoading ? (
        <div className={'text-center'}>
          <Loader height='100px' width='100px' />
          <div>Retrieving account details</div>
        </div>
      ) : (
        <>
          <div className='mb-1 d-flex justify-content-center'>
            <Logo width={80} height={80} />
          </div>
          <div className='d-flex flex-row justify-content-between align-items-center pb-2'>
            <h1 className='mb-0 pt-5 text-semibold'>
              {awaitingVerification ? 'Login link sent' : 'Login'}
            </h1>
          </div>
          {!awaitingVerification ? (
            <div className='mb-3'>
              Login to {Project.siteName} with a{' '}
              {devSettings.whitelabel ? (
                'Magic link'
              ) : (
                <a
                  href='https://help.hoxtonmix.com/en/article/what-are-magic-links-1morzqn/'
                  target='_blank'
                  className='fw-bold text-decoration-none text-primary'
                  rel='noreferrer'
                >
                  Magic link
                </a>
              )}
              . Just enter your account email address and we’ll send a link to
              your inbox.
            </div>
          ) : (
            <div className='mb-4'>
              Check your email inbox, we’ve sent a link to{' '}
              <span className='fw-bold text-primary'>{email}</span>. Just click
              the link in the email and we’ll sign you in.
              <div className='mt-2'>
                <div className='fw-bold mt-5 mb-2'>
                  I haven’t received a link
                </div>
                Is the email above correct?{' '}
                <span
                  onClick={() => setAwatingVerification(false)}
                  className='cursor-pointer fw-bold text-primary'
                >
                  Go Back
                </span>{' '}
                or click the button below to try again.
              </div>
            </div>
          )}
          <form onSubmit={handleSubmit} className='d-flex flex-column mb-2'>
            <ErrorMessage className='mb-5'>
              {error || params?.error}
            </ErrorMessage>
            {!awaitingVerification && (
              <InputGroup
                disabled={isLoading}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                title='Email Address'
                placeholder='Email address'
                className='mb-5'
              />
            )}

            <Button
              id='loginButton'
              type='submit'
              className='mb-4'
              disabled={isLoading}
            >
              {awaitingVerification ? 'Resend' : 'Send'} magic link
            </Button>
            <>
              {!!Project.marketingSiteUrl && (
                <span>
                  Don’t have an account?{' '}
                  <a
                    target='_blank'
                    href={Project.marketingSiteUrl}
                    className='text-action fw-bold cursor-pointer'
                    rel='noreferrer'
                  >
                    Sign Up
                  </a>
                </span>
              )}
            </>
          </form>
        </>
      )}
    </Card>
  )
}

export default Login
