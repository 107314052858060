import dataRelay from 'data-relay'
import { Project } from './project'

export function sendOfferClick(
  sku: string,
  source: string,
  isRedeem?: boolean,
) {
  if (!Project.dataRelay) {
    return
  }
  dataRelay.sendEvent(
    {
      event: isRedeem ? 'Offer Redeem' : 'Offer Click',
      sku: `${sku}`.toLowerCase(),
      source: `Portal (${source})`,
    },
    { apiKey: Project.dataRelay },
  )
}
