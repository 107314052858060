import React, {
  FC,
  FormEvent,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import Input, { InputType } from './Input'
import Dropdown from './Dropdown'
import DropdownItem from './DropdownItem'
import { safeParseEventValue } from 'common/utils/forms/safeParseEventValue'
import { snakeCase } from 'lodash'
import { GUID } from 'common/utils/string/GUID'
import useSearchThrottle from 'common/hooks/useSearchThrottle'
import Loader from 'components/base/Loader'
import classNames from 'classnames'
import Icon from 'project/Icon'

type AutocompleteType = Omit<InputType, 'onChange'> & {
  items: any[] | undefined
  renderItem?: (item: any) => ReactNode
  onChange: (value: string) => void
  noFilter?: boolean
  isLoading?: boolean
  show?: boolean
  onSubmit?: (search: string) => void
  setShow?: (value: boolean) => void
  dropdownClassName?: string
  footer?: ReactNode
  dropdownFooter?: ReactNode
  dropdownHeader?: ReactNode
  error?: any
}

const Autocomplete: FC<AutocompleteType> = ({
  items = [],
  onChange,
  dropdownClassName,
  setShow: setShowProp,
  onSubmit,
  show: showProp,
  renderItem = (v) => (
    <DropdownItem
      onClick={() => {
        onChange(v)
        if (setShowProp) {
          setShowProp(false)
        } else {
          setShow(false)
        }
      }}
    >
      {v}
    </DropdownItem>
  ),
  value,
  noFilter,
  error,
  touched,
  footer,
  isLoading,
  dropdownFooter,
  dropdownHeader,
  ...rest
}) => {
  const { search, searchInput, setSearchInput } = useSearchThrottle('')
  const [_show, _setShow] = useState(false)
  const show = setShowProp ? showProp : _show
  const setShow = setShowProp ? setShowProp : _setShow
  const ref = useRef(GUID())
  const submit = (e: FormEvent) => {
    e.preventDefault()
    onSubmit?.(searchInput)
  }
  useEffect(() => {
    onChange(search)
  }, [search])
  const inner = (
    <form onSubmit={submit}>
      <Input
        key={ref.current}
        value={searchInput}
        isValid={!error}
        touched={touched}
        onChange={(e) => setSearchInput(safeParseEventValue(e))}
        onBlur={() => {
          if (value !== search) {
            onChange(search)
          }
        }}
        onFocus={() => setShow(true)}
        {...rest}
      />
    </form>
  )

  const filteredItems = useMemo(() => {
    if (noFilter) {
      return items
    }
    const lower = snakeCase(search)
    return search ? items.filter((v) => snakeCase(v).includes(lower)) : items
  }, [items, noFilter, search])

  return (
    <>
      <Dropdown
        hidden={!filteredItems.length && !dropdownHeader && !dropdownFooter}
        show={!!show}
        setShow={setShow}
        dropdownClassName={classNames('px-0 autocomplete', dropdownClassName)}
        dropdownContent={
          <>
            {isLoading && (
              <div className='text-center'>
                <Loader />
              </div>
            )}
            {dropdownHeader}
            <div className='autocomplete-content'>
              {filteredItems.map(renderItem)}
            </div>
            {dropdownFooter}
          </>
        }
      >
        <>{inner}</>
      </Dropdown>
      {footer}
    </>
  )
}

export default Autocomplete
