import { FC } from 'react'
import FeaturedOfferCard from './FeaturedOfferCard'

type OffersCardType = {}

const OffersCard: FC<OffersCardType> = ({}) => {
  return (
    <div>
      <h2 className='mb-3'>Our Partners</h2>
      <div className='row'>
        <div className='col-xxl-4 pb-4 col-xl-4 col-lg-6 col-md-12 overflow-hidden'>
          <FeaturedOfferCard
            name='tide'
            image={'/images/tide-team.png'}
            logo={'/images/tide.svg'}
            badge='Banking'
            link='https://www.tide.co/partners/hoxton-mix/'
            id={`efbbc8c9-e80d-4bc5-bdb3-1957fcb73f79`}
            title={
              <>
                <span className='tide-pink'>£75 cashback</span> when you open a
                business bank account for free
              </>
            }
          />
        </div>
        <div className='col-xxl-4 pb-4 col-xl-4 col-lg-6 col-md-12 overflow-hidden'>
          <FeaturedOfferCard
            name='xero'
            badge='Accounting'
            className={'xero'}
            image={'/images/xero-hero.svg'}
            logo={'/images/xero-logo.svg'}
            link='https://www.tide.co/partners/hoxton-mix/'
            id={`a88ebd88-04d3-4eaa-afde-744861b66b2c`}
            title={
              <>
                <span className='tide-pink'>90% off for 6 months</span>{' '}
                Exclusively to Hoxton Mix customers
              </>
            }
          />
        </div>
      </div>
    </div>
  )
}

export default OffersCard
