import { FC, useEffect, useRef, useState } from 'react'
import AutoComplete from './base/forms/AutoComplete'
import Icon from 'project/Icon'
import { useDefaultSubscription } from 'common/services/useDefaultSubscription'
import Badge from './base/Badge'
import { useGetMailCategoriesQuery } from 'common/services/useMailCategory'
import MailCategory from './MailCategory'
import { useRouter } from 'next/router'
import Link from 'next/link'

type SiteSearchType = {}

const SiteSearch: FC<SiteSearchType> = ({}) => {
  const { defaultSubscriptionId } = useDefaultSubscription()
  const router = useRouter()
  const { data } = useGetMailCategoriesQuery(
    {
      subscription: `${defaultSubscriptionId}`,
    },
    { skip: !defaultSubscriptionId },
  )
  const [search, setSearch] = useState('')
  const [show, setShow] = useState(false)

  const companyClick = () => {
    setShow(false)
  }
  // Close dropdown on route change
  useEffect(() => {
    const handleRouteChange = () => setShow(false)
    router.events.on('routeChangeStart', handleRouteChange)

    // Cleanup the event listener on component unmount
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [router])
  const inputRef = useRef()
  return (
    <AutoComplete
      inputRef={inputRef}
      onSubmit={(search) => {
        router.push(`/mail/table?q=${search}`)
        inputRef.current?.blur()
      }}
      show={show}
      setShow={setShow}
      iconLeft={<Icon name={'search'} />}
      dropdownClassName={'w-100 py-3 pb-1'}
      items={[]}
      onChange={setSearch}
      renderItem={() => null}
      dropdownHeader={
        (!!data?.categories?.length || data?.senders?.length) && (
          <div className='px-4 mb-4 d-flex flex-column gap-2 text-blue-grey'>
            <div className='fw-semibold d-flex align-items-center gap-2 mb-1'>
              Quick Filters <Icon name='filter' />
            </div>
            <div className='d-flex gap-2 flex-wrap'>
              {data?.senders?.slice(0, 5).map((v) => (
                <Link
                  key={v.name}
                  href={`/mail/table?company=${encodeURIComponent(v.name)}`}
                >
                  <Badge
                    className='px-2 py-1 cursor-pointer'
                    theme='secondary-outline'
                  >
                    <div className='d-flex fw-semibold letter-space gap-2 align-items-center'>
                      <img
                        style={{ borderRadius: 10 }}
                        src={v.logo?.url || '/images/default-company.svg'}
                        height={20}
                        width={20}
                      />
                      <span className='lh-1'>{v.name.toUpperCase()}</span>
                    </div>
                  </Badge>
                </Link>
              ))}
            </div>
            <div className='d-flex mt-1 flex-wrap gap-2'>
              {data?.categories?.slice(0, 5).map((v) => (
                <Link
                  href={`/mail/table?category=${encodeURIComponent(v.name)}`}
                >
                  <MailCategory value={v.name} />
                </Link>
              ))}
            </div>
          </div>
        )
      }
    />
  )
}

export default SiteSearch
