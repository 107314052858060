import { KYCMember, Res } from 'common/types/responses'
import { Req } from 'common/types/requests'
import { service } from 'common/service'
import { getApi } from 'common/api-common'

export const kycMemberService = service
  .enhanceEndpoints({ addTagTypes: ['KycMember'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      createKycMember: builder.mutation<
        Res['kycMember'],
        Req['createKycMember']
      >({
        invalidatesTags: [{ id: 'LIST', type: 'KycMember' }],
        query: (query: Req['createKycMember']) => ({
          body: query.data,
          method: 'POST',
          url: `kyc/verifications?subscription_id=${query.subscription}`,
        }),
      }),
      deleteKycMember: builder.mutation<
        Res['kycMember'],
        Req['deleteKycMember']
      >({
        invalidatesTags: [{ id: 'LIST', type: 'KycMember' }],
        query: (query: Req['deleteKycMember']) => ({
          body: query,
          method: 'DELETE',
          url: `kyc/verifications/${query.id}`,
        }),
      }),
      getKycMember: builder.query<Res['kycMember'], Req['getKycMember']>({
        providesTags: (res) => [{ id: res?.id, type: 'KycMember' }],
        query: (query: Req['getKycMember']) => ({
          url: `kyc/p/verifications`,
        }),
        // queryFn: () => ({ data: responses.kycMember }),
      }),
      getKycMembers: builder.query<Res['kycMembers'], Req['getKycMembers']>({
        providesTags: [{ id: 'LIST', type: 'KycMember' }],
        query: (q) => ({
          url: `kyc/verifications?subscription_id=${q.subscription}`,
        }),
        // queryFn: () => ({ data: responses.kycMembers }),
      }),
      updateKycMember: builder.mutation<
        Res['kycMember'],
        Req['updateKycMember']
      >({
        invalidatesTags: (res) => [
          { id: 'LIST', type: 'KycMember' },
          { id: res?.id, type: 'KycMember' },
        ],
        query: (query: Req['updateKycMember']) => {
          const token = getApi().getToken?.()
          return {
            body: query,
            method: 'PUT',
            url: token
              ? `kyc/p/verifications`
              : `kyc/verifications/${query.id}`,
          }
        },
      }),
      // END OF ENDPOINTS
    }),
  })

export async function getKycMembers(
  store: any,
  data: Req['getKycMembers'],
  options?: Parameters<
    typeof kycMemberService.endpoints.getKycMembers.initiate
  >[1],
) {
  return store.dispatch(
    kycMemberService.endpoints.getKycMembers.initiate(data, options),
  )
}
export async function getKycMember(
  store: any,
  data: Req['getKycMember'],
  options?: Parameters<
    typeof kycMemberService.endpoints.getKycMember.initiate
  >[1],
) {
  return store.dispatch(
    kycMemberService.endpoints.getKycMember.initiate(data, options),
  )
}
export async function createKycMember(
  store: any,
  data: Req['createKycMember'],
  options?: Parameters<
    typeof kycMemberService.endpoints.createKycMember.initiate
  >[1],
) {
  return store.dispatch(
    kycMemberService.endpoints.createKycMember.initiate(data, options),
  )
}
export async function updateKycMember(
  store: any,
  data: Req['updateKycMember'],
  options?: Parameters<
    typeof kycMemberService.endpoints.updateKycMember.initiate
  >[1],
) {
  return store.dispatch(
    kycMemberService.endpoints.updateKycMember.initiate(data, options),
  )
}
export async function deleteKycMember(
  store: any,
  data: Req['deleteKycMember'],
  options?: Parameters<
    typeof kycMemberService.endpoints.deleteKycMember.initiate
  >[1],
) {
  return store.dispatch(
    kycMemberService.endpoints.deleteKycMember.initiate(data, options),
  )
}
// END OF FUNCTION_EXPORTS
export function memberInvalid(v: Partial<KYCMember>) {
  if (!v.first_name || !v.last_name) {
    return true
  }
  return false
}
export const {
  useCreateKycMemberMutation,
  useDeleteKycMemberMutation,
  useGetKycMemberQuery,
  useGetKycMembersQuery,
  useUpdateKycMemberMutation,
  // END OF EXPORTS
} = kycMemberService

/* Usage examples:
const { data, isLoading } = useGetKycMembersQuery({ id: 2 }, {}) //get hook
const [createKycMembers, { isLoading, data, isSuccess }] = useCreateKycMembersMutation() //create hook
kycMemberService.endpoints.getKycMembers.select({id: 2})(store.getState()) //access data from any function
*/
