import { FC } from 'react'
import classNames from 'classnames'

const themes = {
  danger: 'bg-danger text-danger bg-opacity-10',
  info: 'bg-info text-info bg-opacity-10',
  outline:
    'fs-xss text-dark300 border border-dark300 border-1 rounded-pill py-half',
  primary: 'bg-primary text-primary bg-opacity-10',
  secondary:
    'bg-secondary  bg-light200 outline fw-semibold text-blue-grey-dark border-light300',
  'secondary-outline': 'bg-light200 outline border-light300 text-blue-grey',
  success: 'bg-success text-success bg-opacity-10',
  warning: 'bg-warning text-warning bg-opacity-10',
}

const sizes = {
  counter: 'badge--counter',
  default: '',
  dot: 'badge--dot',
}

type BadgeType = {
  size?: keyof typeof sizes
  className?: string
  theme?: keyof typeof themes
  children: React.ReactNode
}

const Badge: FC<BadgeType> = ({
  children,
  className,
  size,
  theme = 'primary',
}) => {
  const themeClass = themes[theme]
  const sizeClass = size && sizes[size]

  return (
    <div
      className={classNames(
        'badge d-flex fs-xs text-nowrap align-items-center justify-content-center text-center',
        themeClass,
        sizeClass,
        className,
      )}
    >
      {children}
    </div>
  )
}

export default Badge
