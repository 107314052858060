import { FC, ReactNode } from 'react'
import Card from 'components/base/Card'
import Button from 'components/base/forms/Button'
import Link from 'next/link'
import cn from 'classnames'
import dataRelay from 'data-relay'
import { Project } from 'common/project'
import { sendOfferClick } from 'common/sendOfferClick' // we need this to make JSX compile

type TideCardType = {
  title: ReactNode
  logo: string
  name: string
  image: string
  id: string
  className?: string
  style?: object
  link?: string
  badge?: string
}

const FeaturedOfferCard: FC<TideCardType> = ({
  badge = 'Members',
  className,
  id,
  image,
  link,
  logo,
  name,
  title,
}) => {
  return (
    <Card padding={0} className={cn(className, 'featured-offer-card h-100')}>
      <div className='flex-row h-100'>
        <div className='d-flex flex-column justify-content-between'>
          <div>
            <Button
              size='extra-small'
              theme='lightOutline'
              className='px-2 mb-2 fw-semibold cursor-default'
            >
              {badge}
            </Button>
            <h3 className='mb-2 lh-1 text-semibold text-white mt-2'>
              <>{title}</>
            </h3>
            <div className={'text-left'}>
              <img src={logo} className={'ad-brand'} />
            </div>
          </div>
          <div className='flex-row mt-4'>
            {link ? (
              <a
                href={link}
                className='btn btn-primary'
                onClick={() => {
                  sendOfferClick(name, document.location.pathname)
                }}
                target='_blank'
                rel='noreferrer'
              >
                Claim Offer
              </a>
            ) : (
              <Link href={`/marketplace/${id}/`}>
                <Button
                  onClick={() => {
                    sendOfferClick(name, document.location.pathname)
                  }}
                  className='px-4'
                >
                  Claim Offer
                </Button>
              </Link>
            )}
          </div>
        </div>
        <img className='character' src={image} />
      </div>
    </Card>
  )
}

export default FeaturedOfferCard
