import { FC } from 'react'
import { CategoryName } from 'common/types/responses'
import { Constants } from 'common/utils'
import { Project } from 'common/project'
import Badge from './base/Badge' // we need this to make JSX compile

type MailCategoryType = {
  value: CategoryName
}

const MailCategory: FC<MailCategoryType> = ({ value }) => {
  const color =
    Constants.mailTags[value] || Object.values(Constants.mailTags)[0]
  return (
    <Badge className='px-2 py-1 cursor-pointer ' theme='secondary-outline'>
      <div className='d-flex fw-semibold letter-space gap-2 align-items-center'>
        <svg
          width='10'
          height='9'
          viewBox='0 0 10 9'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <circle cx='4.96484' cy='4.5' r='4.5' fill={color} />
        </svg>
        {(value || '').toUpperCase()}
      </div>
    </Badge>
  )
}

export default MailCategory
