import React, { FC, useState } from 'react'

import Icon from 'project/Icon'
import Button from './base/forms/Button'
import ModalDefault from './base/ModalDefault'
import Topup from './Topup'
import { useDefaultSubscription } from 'common/services/useDefaultSubscription'
import { Format } from 'common/utils/format'
import { useGetManageSubscriptionQuery } from 'common/services/useManageSubscription'
import clearCBCookies from 'project/clearCBCookies'

type TopupSelectType = {}

const TopupSelect: FC<TopupSelectType> = ({}) => {
  const [hovered, setHovered] = useState(false)
  const [showTopup, setShowTopup] = useState(false)
  const { hasPermission, subscriptionDetail } = useDefaultSubscription()
  const canTopup = hasPermission('TOPUP')
  const isPartnerCustomer = subscriptionDetail?.is_partner_customer
  const { data: manageSubscriptionUrl, refetch } =
    useGetManageSubscriptionQuery(
      {
        id: `${subscriptionDetail?.company_id}`,
      },
      {
        skip: !subscriptionDetail?.company_id,
      },
    )

  return (
    <div className='nav-item dropdown'>
      <a
        onClick={() => setShowTopup(true)}
        className={`lh-input cursor-pointer outline-dark rounded-xl py-0 px-2 flex-row align-items-center border-rounded-full text-body btn-light`}
      >
        <svg
          className='ms-4'
          width='23'
          height='23'
          viewBox='0 0 23 23'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M19.3566 12.2866V9.14369C19.3566 8.72693 19.191 8.32723 18.8963 8.03253C18.6016 7.73783 18.2019 7.57227 17.7852 7.57227H2.85658C2.43982 7.57227 2.04012 7.73783 1.74542 8.03253C1.45072 8.32723 1.28516 8.72693 1.28516 9.14369V20.1437C1.28516 20.5605 1.45072 20.9602 1.74542 21.2549C2.04012 21.5496 2.43982 21.7151 2.85658 21.7151H17.7852C18.2019 21.7151 18.6016 21.5496 18.8963 21.2549C19.191 20.9602 19.3566 20.5605 19.3566 20.1437V16.2151'
            stroke='#000001'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M6.5332 3.64363L15.4432 1.31792C15.5433 1.28971 15.6481 1.28208 15.7513 1.29549C15.8544 1.30889 15.9538 1.34306 16.0434 1.39593C16.133 1.4488 16.2109 1.51927 16.2725 1.60309C16.3341 1.68692 16.3781 1.78235 16.4018 1.88363L16.8418 3.64363'
            stroke='#000001'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
          <path
            d='M20.9272 12.2871H16.9986C16.5647 12.2871 16.2129 12.6389 16.2129 13.0728V15.43C16.2129 15.8639 16.5647 16.2157 16.9986 16.2157H20.9272C21.3611 16.2157 21.7129 15.8639 21.7129 15.43V13.0728C21.7129 12.6389 21.3611 12.2871 20.9272 12.2871Z'
            stroke='#000001'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>

        <span className={`mx-2 flex-fill text-truncate`}>
          {Format.money(subscriptionDetail?.company?.account_balance || 0)}
        </span>
        <span className='me-3'>
          <svg
            width='19'
            height='11'
            viewBox='0 0 19 11'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M18.1343 2.25767L10.1116 10.7321C10.0319 10.817 9.93677 10.8843 9.83184 10.9303C9.7269 10.9763 9.61424 11 9.50044 11C9.38663 11 9.27397 10.9763 9.16904 10.9303C9.06411 10.8843 8.96901 10.817 8.88932 10.7321L0.866572 2.25767C0.63164 2.00808 0.5 1.67215 0.5 1.32222C0.5 0.972298 0.63164 0.636368 0.866572 0.386777C0.982174 0.264276 1.11996 0.166995 1.2719 0.100595C1.42385 0.034194 1.58693 0 1.75165 0C1.91637 0 2.07944 0.034194 2.23139 0.100595C2.38334 0.166995 2.52112 0.264276 2.63672 0.386777L9.50044 7.6366L16.3624 0.386777C16.4781 0.264359 16.616 0.16716 16.768 0.100818C16.92 0.0344753 17.0832 0.000312805 17.2479 0.000312805C17.4127 0.000312805 17.5758 0.0344753 17.7278 0.100818C17.8798 0.16716 18.0177 0.264359 18.1334 0.386777C18.3684 0.636368 18.5 0.972298 18.5 1.32222C18.5 1.67215 18.3692 2.00808 18.1343 2.25767Z'
              fill='#1D1D1F'
            />
          </svg>
        </span>
      </a>
      <ModalDefault
        isOpen={showTopup}
        onDismiss={() => setShowTopup(false)}
        toggle={() => setShowTopup(!showTopup)}
      >
        <Topup
          closeModal={() => setShowTopup(false)}
          onComplete={() => setShowTopup(false)}
        />
      </ModalDefault>
    </div>
  )
}

export default TopupSelect
