import { FC } from 'react' // we need this to make JSX compile

type TitleValueType = {
  title: string
  value: string | false | null
  className?: string
}

const TitleValue: FC<TitleValueType> = ({ className, title, value }) => {
  if (!value) return null
  return (
    <div className={className}>
      <div className='text-muted mb-2'>{title}</div>
      <h4 className='text-semibold'>{value}</h4>
    </div>
  )
}

export default TitleValue
