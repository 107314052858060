import { FC, lazy, Suspense } from "react";
import { useDevSettings } from "../common/services/useDevSettings";

type LogoType = {
  width?: number;
  height?: number;
};

const Logo: FC<LogoType> = ({  height = 40, width = 40, ...rest }) => {
  const { devSettings } = useDevSettings()
  const whitelabel = devSettings.whitelabel
  return (
      <>
        {(!whitelabel || whitelabel === "hoxtonmix") ? <img src={"/images/robo-head.png"} height={35} />
        :<span className='fa fa-user-circle text-muted' style={{fontSize:35}}/> }
      </>
  );
};

export default Logo;
